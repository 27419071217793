.navbar {
	width: 100%;
	height: 80px;
	background-color: rgb(19, 19, 19);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.links {
	margin-right: 50px;
	display: flex;
	align-items: center;
}

.links a {
	text-decoration: none;
	color: white;
	margin-left: 20px;
	font-size: 25px;
}
